@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.container {
 display: flex;
 flex-wrap: nowrap;
 overflow-x: hidden;
 overflow-y: hidden;
}

.container::-webkit-scrollbar {
  height: 1vh;
  margin: 20px;
  display: none;
}

.container::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
  display: none;
}

.container::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
  margin: 20px;
  display: none;

  
}

.typewriter-container {
  font-size: 3em;
  font-family: 'Helvetica', sans-serif;
  width: 100vw;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


}

.highlight {
  background: linear-gradient(90deg, rgba(115,3,192,1) 0%, rgba(236,56,188,1) 50%, rgba(253,239,249,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
 
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 100vw;
  color: white;
  
}

.section-1 {
  background-color: #18191A;
  color: white;
  flex-direction: column;
}

.section-1 h1 {
  overflow: hidden;
  border-right: .15em solid white;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .0em;
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 96px;
}

@keyframes typing {
  from {width: 0;}
  to { width: auto }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }
}

.section-2 {
  background-color: #18191A;
}

.section-3 {
  background-color: #18191A;
  display: flex;
  flex-direction: column;


}

.section-4 {
  background-color: #18191A;
}

.section-5 {
  background-color: #18191A;
}

.vertical-sections {
  display: flex;
  flex-direction: column;
}

.horizontal-sections {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 600vw; /* Adjust this to accommodate the number of horizontal sections */
  background-color: #18191A;
}

.navbar{
  height: 100vh;
  width: 100px;
  background-color: #111012;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  flex-direction: column;
  transform: translateX(-100px);
  margin-right: 100px;
  animation: fadeIn 3s forwards;
  z-index: 50;
}

.topSect .bottomSect{
  display: flex;
  flex-direction: column;
  transition: 0.3;
}

.bottomSect a {
  width: 100px;
  height: 100px;
  background-color: transparent;
  color: #A7A7A7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid white;
  border-bottom: 2px solid white; 
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  font-family: 'HelveticaLight', sans-serif;
}

.navButton {
  width: 100px;
  height: 100px;
  background-color: transparent;
  color: #A7A7A7;
  border: none;
  font-size: 20px;
  font-family: 'HelveticaLight', sans-serif;
  text-align: center;
  transition: 0.3s;

}

.navButton:hover{
  color: white;
  cursor: pointer;
  transition: 0.3s;
  font-family: 'Helvetica', sans-serif; 

}

.bottomSect a:hover{
  color: white;
  transition: 0.3s;
  cursor: pointer;
  font-family: 'Helvetica', sans-serif; 
}


.CTAButton {
  transform: translateY(-20px);
  margin-top: 20px;
  animation: fadeIn 1s forwards;
  background: linear-gradient(90deg, rgba(115,3,192,1) 0%, rgba(236,56,188,1) 50%, rgba(253,239,249,1) 100%);
  width: 300px;
  height: 65px;
  color: #18191A;
  border: none;
  font-family: 'Helvetica', sans-serif;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50px;
  transition: 0.3s;
}

.CTAButton:hover{
  box-shadow: inset 4px 4px  .5em #00000075;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes vertFade {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@font-face {
  font-family: 'Helvetica';
  src: url('./fonts/HelveticaNeueMedium.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'HelveticaLight';
  src: url('./fonts/HelveticaNeueLight.otf') format('opentype');
  font-weight: normal;
}

.inner{
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  width: 91vw;
  height: 100vh;
  justify-content: space-evenly;
}

.titleHeader{
  width: 95vw;
  height: auto;
}

.titleCard{
  width: 20vw;
  height: 5vh;
  border-radius: 15px;
  color: #18191A;
  background: linear-gradient(90deg, rgba(115,3,192,1) 0%, rgba(236,56,188,1) 50%, rgba(253,239,249,1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-family: 'Helvetica';

}

.divider{
  width: 100px;
  background-color: #18191A;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  left: 50px;
}

.line{
  height: 100vh;
  width: 10px;
  background: linear-gradient(180deg, rgba(253,239,249,1) 0%, rgba(236,56,188,1) 50%, rgba(115,3,192,1) 100%);
}

.middle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 91vw;
}

.bottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 91vw;
}

.card{
  border-radius: 55px;
  background-color: #111012;
  color: white;
  font-family: Helvetica;
  overflow: hidden;
}

.card.XL{
  width: 20vw;
  height: 55vh;
}

.card.L {
  height: 25vh;
  width: 33vw;
  display: flex;

}

.card.S{
  height: 30vh;
  width: 20vw;
}

.card.Long{
  width: 68.5vw;
  height: 30vh;
}

.card.work{
  height: auto;
  width: auto;
  padding: 20px;
}

.interior{
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;
  height: inherit;
}

.interior  h1  {
  font-size: 32px;
  margin: 10px;
  padding-top: 20px;
  padding-right: 20px;
}

.interior p {
  font-size: 24px;
  margin: 10px;
  padding-right: 20px;
  font-family: 'HelveticaLight';
}

.firstLine h1 {
  font-size: 32px;
  margin: 10px;
  padding-top: 20px;
  padding-right: 20px;
}

.firstLine p {
  font-size: 32px;
}

@media screen and (max-width: 1920px) {
  .interior h1 {
    font-size: 24px;
    margin: 10px;
    padding-top: 20px;

  }

  .interior p {
    font-size: 18px;
    margin: 10px
  }

  .firstLine h1 {
    font-size: 24px;
    margin: 10px;
    padding-top: 20px;
  }

  .firstLine p {
    font-size: 24px;
    margin: 10px
  }

  .card.work{
    height: auto;
    width: auto;
    padding: 20px;
  }
}

.interior img{
  width: 20vw;
  height: 40vh;
}



.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.5vw;
  row-gap: 5vh;
  align-content: space-between;
}

.grid.L{
  column-gap: 1vw;
  grid-template-columns: 0.2fr 0.2fr 0.2fr;
  row-gap: 1vh;
  background-color: transparent;
  margin: 0.5em;
}

.skillset {
  width: 7vw;
  height: 3vh;
  border: 2px solid white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 22px;
}

.title-area {
  font-size: 32px;
  margin: 0.5em;
}

.description {
  margin: 0.5em;
  font-family: 'HelveticaLight';
}

@media screen and (max-width: 1920px) {
  .skillset {
    width: 8.5vw;
    height: 3vh;
    border: 2px solid white;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-size: 20px;
  }

  .title-area{
    font-size: 22px;
  }
  
  .description{
      font-size: 22px;
      height: 70px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
  }

  .link{
    width: 2.5vw;
    height: 5vh
  }

  .expand{
    width: 3vw;
    height: 6vh;
  }
  
}

.bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.title-area h1 {
  margin: 0;
}

.title-area h3 {
  margin: 0;
  font-family: 'HelveticaLight';
  font-weight: 300;
}

.description {
  margin: 0.5em;
}


.buttonCont {
  display: flex;
  gap: 10px; /* Space between the buttons */
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}


.link{
  display: inline-block;
  width: 2vw;
  height: 4vh;
  border-radius: 50%;
  border: 4px solid transparent; /* Make space for the gradient border */
  background-image: linear-gradient(#111012, #111012), /* bottom layer: white background */
                    linear-gradient(to bottom, #7303c0, #ec38bc, #fdeff9); /* top layer: gradient border */
  background-origin: border-box; /* Ensure gradient border is visible */
  background-clip: content-box, border-box; /* Clip the background */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  
}



.expand{
  display: inline-block;
  width: 2.5vw;
  height: 5vh;
  border-radius: 50%;
  border: 4px solid transparent; /* Make space for the gradient border */
  background-image: linear-gradient(#111012, #111012), /* bottom layer: white background */
                    linear-gradient(to bottom, #7303c0, #ec38bc, #fdeff9); /* top layer: gradient border */
  background-origin: border-box; /* Ensure gradient border is visible */
  background-clip: content-box, border-box; /* Clip the background */
   display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

 .icon{
  width: 3vw;
  height: 3vh;
}

.link:hover, .expand:hover {
  width: 2.5vw;
  height: 5vh;
  border-radius: 50%;
  background-image: linear-gradient(#18191A, #18191A),
                    linear-gradient(to bottom, #7303c0, #ec38bc, #fdeff9); 
  transition: all 0.3s ease-in-out;
  background-origin: border-box; /* Ensure gradient border is visible */
  background-clip: content-box, border-box; /* Clip the background */
}

.expand:hover{
  width: 3vw;
  height: 6vh;
  cursor: pointer;
}



.borderGradient .icon {
  width: 32px; /* Icon width */
  height: 32px; /* Icon height */
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid white;
  display: inline-block;
  margin-right: 5px;
}

.indicator.active {
  background-color: white;
  border-color: white;
}

#skills-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.skill h3 {
  font-size: 16px;
  margin: 0;
  font-family: 'HelveticaLight';
}

.skill {
  text-align: center;
  width: 5vw;
}

.skill img{
  width: 100px;
  height: 100px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(10px); /* Apply blur effect */
  z-index: 10; /* Ensure it is above other content */
  cursor: pointer; /* Makes the overlay clickable */
}

/* Expandable div */
.expandableDiv {
  position: fixed;
  overflow: hidden;
  bottom: 5%;
  left: 12%;
  width: 80%;
   /* Limit the height of the expandable div */
  background: #18191A;
  overflow-y: auto;
  animation: slideUp 0.5s ease-out forwards; 
  padding: 20px;
  height: 85vh;
  z-index: 20; /* Ensure it's above the overlay */
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expandableDiv::-webkit-scrollbar {
  display: none;
}



/* Animation for sliding up the expandable div */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Description that appears on upward scroll */
.description-scroll {
  margin-top: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.description-scroll.show {
  opacity: 1;
}

.imageSlider {
  width: 91%;
  max-width: auto;
  margin: 0 auto; /* Center the slider */
}

/* Individual slide */
.imageSlider div {
  position: relative;
  text-align: center;
}

/* Image styling */
.imageSlider img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: fill; /* Cover the container while maintaining aspect ratio */
  border-radius: 50px;
}

/* Slick dots */
.slick-dots {
  bottom: -30px;

}

.slick-dots li button:before {
  font-size: 12px;
  color: #999;
}

.slick-dots li.slick-active button:before {
  color: #fff;
}

/* Slick arrows */
.slick-prev, .slick-next {
  font-size: 10px;
  z-index: 1;
  color: transparent;
}

.slick-prev:hover, .slick-next:hover {
  color: transparent;
  
}

.slick-prev {
  left: -55px;
}

.iconImage {
  width:25%;
  height: 50%;
  overflow: hidden;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(115,3,192,1) 0%, rgba(236,56,188,1) 50%, rgba(253,239,249,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.iconImage img {
  width: 96%;
  height: 96%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  

}

.ContactInfo {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}

.center {
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ContactInfo h1 {
margin: 0;
font-family: Helvetica;
}

.ContactInfo h3 {
  margin: 5px;
  font-family: 'HelveticaLight';
  font-weight: 100;
}

.mobile{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #18191A;
}

.typewriter-container-mobile {
  font-size: 4vw;
  font-family: 'HelveticaLight';
  color: #FFFFFF;
  text-align: center;
}


.mobileIcon {
  width: 25vw;
  transform: translateY(-20px);
  animation: fadeIn 1s forwards;
  transition: 0.3s;
  margin-bottom: 10px;
}

.sliderHeader{
display: flex;
justify-content: space-between;
align-items: center;
font-family: 'HelveticaLight';
}
